import React from "react"
import VideoPlayer from "./VideoPlayer"

function VideosTab() {
    return (
        <div className = ''>
            <VideoPlayer />
            </div>
    )
}

export default VideosTab;