import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactPlayer from 'react-player';
import { FaFileVideo } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';

const VideoPlayer = () => {
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [fileId, setFileId] = useState<string | null>(null);
    const [playing, setPlaying] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [hideRun, setHideRun] = useState(false);
    const token = localStorage.getItem('token');
    const baseURL = process.env.REACT_APP_BASE_URL;


    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles[0]) {
            const file = acceptedFiles[0];
            const url = URL.createObjectURL(file);
            setVideoUrl(url);
            setVideoFile(file);
            setPlaying(true);
            uploadFile(file); // Start uploading immediately
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const uploadFile = async (file: File) => {
        setUploading(true);
        const formData = new FormData();
        formData.append('video', file);
        const toastId = toast.info('Uploading file', {
            autoClose: false, // Prevent the toast from closing automatically
          });
        try {
            const response = await axios.post(`${baseURL}/video/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
                onUploadProgress: progressEvent => {
                    const total = progressEvent.total || 1; // Avoid division by zero
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
                    setUploadProgress(percentCompleted);
                }
            });
            setFileId(response.data.file_id);
            toast.update(toastId, {
                render: 'Video uploaded successfully!',
                type: 'success',
                autoClose: 5000 // Close after 5000ms
              });
        } catch (error) {
            console.error('Error uploading video:', error);
            toast.update(toastId, {
                render: 'Failed to upload video.',
                type: 'error',
                autoClose: 5000 // Close after 5000ms
              });
        }
        setUploading(false);
    };

    const handleRunTheftDetection = async () => {
        if (!fileId) {
            toast.error('No file uploaded.');
            return;
        }
        const toastId = toast.info('Running Theft detection', {
            autoClose: false, // Prevent the toast from closing automatically
          });

        try {
            setHideRun(true);
            await axios.post(`${baseURL}/video/run`, {
                video_id: fileId
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`                }
            });
            toast.update(toastId, {
                render: 'Theft detection completed successfully!',
                type: 'success',
                autoClose: 5000 // Close after 5000ms
              });
            setHideRun(true);
        } catch (error:any) {
            console.error('Error running theft detection:', error);
            toast.update(toastId, {
                render: `Error running theft detection: ${error.message}`,
                type: 'error',
                autoClose: 5000 // Close after 5000ms
              });
            setHideRun(true);
        }
    };

    const handlePlay = () => {
        setPlaying(!playing);
    };

    const clearVideo = () => {
        setVideoUrl(null);
        setVideoFile(null);
        setPlaying(false);
        setUploadProgress(0);
        setUploading(false);
        setFileId(null);
    };

    return (
        <div className="mx-20 my-10">
            {!videoUrl && (
                <div {...getRootProps()} className="border-2 border-dashed border-gray-400 h-[640px] flex justify-center w-full items-center bg-[#F2F7FF]">
                    <input {...getInputProps()} />
                    {isDragActive ?
                        <p>Drop the files here ...</p> :
                        <div className='flex flex-col gap-4  justify-center items-center'>
                            <FaFileVideo className="w-36 h-36 text-brand-blue-light" />
                            <span className='font-lg'>Drag and drop your video file or select by clicking here to run theft detection</span>
                        </div>
                    }
                </div>
            )}
            {videoUrl && (
                <div className='flex flex-col justify-center items-center'>
                    <ReactPlayer url={videoUrl} playing={playing} controls width="1200px" height="640px" />
                    {uploading ? (
                        <div className="flex justify-center mt-4 w-16 h-16">
                            Upload progress: <CircularProgressbar value={uploadProgress} text={`${uploadProgress}%`} />
                        </div>
                    ) : (
                        <div className="flex justify-center space-x-4 mt-4">
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={clearVideo}>
                                Clear
                            </button>
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handlePlay}>
                                {playing ? 'Pause' : 'Play'}
                            </button>
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" disabled={!fileId || uploading} onClick={handleRunTheftDetection}>
                                Run Theft Detection
                            </button>
                        </div>
                    )}
                </div>
            )}
            <ToastContainer position='bottom-right'/>
        </div>
    );
};

export default VideoPlayer;
