import React, { useEffect } from "react"
import { FiHome, FiClipboard, FiCalendar, FiSettings, FiLifeBuoy } from "react-icons/fi";
import { FcCallback, FcHome, FcAutomatic, FcIntegratedWebcam } from "react-icons/fc";

import { Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";
import { Sidebar, SidebarItem } from "../components/Sidebar";
import TopBar from "../components/TopBar";
import Alerts from "./Alerts";
import Home from "./Home";
import Inputs from "./Inputs";
import Settings from "./Settings";
import Help from "./Help";
import Footer from "../components/Footer";


function Main() {
  const navigate = useNavigate();
  useEffect(() => {
    const loggedIn = localStorage.getItem('token');
    if (!loggedIn) {
      navigate('/login');  // Navigate to home if logged in
    } 
  }, []);
  
  return (
    <div className="flex tk-proxima-nova">
        <Sidebar>
          <SidebarItem icon={<FcHome size={20} />} text="Home" link="/home" itemId="home" />
          <SidebarItem icon={<FcCallback size={20} />} text="Alerts" link="/alerts" itemId="alerts" />
          <SidebarItem icon={<FcIntegratedWebcam size={20} />} text="Cameras" link="/cameras" itemId="cameras" />
          <hr className="my-3" />
          <SidebarItem icon={<FcAutomatic size={20} />} text="Settings" link="/settings" itemId="settings" />
          {/* <SidebarItem icon={<FiLifeBuoy size={20} />} text="Help" link="/help" itemId="help" /> */}
        </Sidebar>
        <div className="h-screen flex-1 flex flex-col">
          <TopBar />
          <main className="flex-1">
            <Routes>
              <Route element={<PrivateRoutes />} >
                <Route path="/home" element={<Home />} />
                <Route path="/alerts" element={<Alerts />} />
                <Route path="/cameras" element={<Inputs />} />
                <Route path="/settings" element={<Settings />} />
                {/* <Route path="/help" element={<Help />} /> */}
                <Route path="*" element={<Navigate to="/home" />} />
              </Route>
            </Routes>
          </main>
          
        <Footer />
      </div>

    </div>
  );
}

export default Main;

const PrivateRoutes = () => {
  const token = localStorage.getItem('token');
  return (token ? <Outlet /> : <Navigate to="/login" />);
}